html, body {
  margin: 0;
  height: 100%;
 
}

h1, p {
  font-family: Lato;
  margin: 0;
}

#root {

  height: 100vh;
}
.ant-card-body{
  height: 100%;
  overflow: scroll;
  padding-bottom: 20px;
}

.ant-upload-list-picture-card-container{
  margin: 8px 8px 8px 0 !important;
}
